<template>
  <b-modal
    id="request-coaching-modal"
    ref="request-coaching-modal"
    footer-class="modal-footer-class"
    centered
    size="lg"
    :ok-title="$t('Submit')"
    ok-only
  >
    <template #modal-title>
      ☝️ <span class="font-weight-bolder">{{ $t('Answer the following questions in order for us to be able to understand your needs') }}</span>
    </template>
    <b-row>
      <b-col>
        <validation-observer ref="request-coaching-modal-observer">
          <validation-provider
            v-slot="{ errors }"
            name="reasonForCoachingRequest"
            rules="required|max:1400"
            mode="lazy"
          >
            <b-form-group>
              <label for="reason-for-coaching-request">
                {{ $t('For which professional issue do you want coaching on?') }} <required-field />
              </label>
              <b-form-textarea
                id="reason-for-coaching-request"
                v-model="reasonForCoachingRequest"
                rows="3"
                no-resize
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <validation-provider
              v-slot="{ errors }"
              name="acceptTermsAndConditions"
              :rules="{
                required: {
                  allowFalse: false
                }
              }"
              mode="lazy"
            >
              <b-form-checkbox
                v-model="acceptTermsAndConditions"
                value="1"
              >
                {{ $t('I agree to') }}
                <b-link :to="{ name: 'mentoring-terms' }" target="_blank">{{ $t('the terms and conditions of WoT') }}</b-link>
                {{ $t('and') }}
                <b-link :to="{ name: 'privacy-policy' }" target="_blank">{{ $t('the privacy policy') }}</b-link>
                {{ $t('as a candidate for coaching') }} <required-field /><br/>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-checkbox>
            </validation-provider>
        </validation-observer>
      </b-col>
    </b-row>
    <template #modal-footer class="d-flex justify-content-between">
      <required-field-explanation />
      <b-button variant="primary" @click="requestCoaching">
        {{ $t('Submit') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BButton,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormTextarea,
  BLink,
  BModal,
  BRow
} from "bootstrap-vue";
import { required } from '@mentoring-platform-validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import RequiredField from '@mentoring-platform/views/components/blocks/RequiredField.vue'
import RequiredFieldExplanation from '@mentoring-platform/views/components/blocks/RequiredFieldExplanation.vue'

export default {
  components: {
    BButton,
    BCol,
    BFormCheckbox,
    BFormGroup,
    BFormTextarea,
    BLink,
    BModal,
    BRow,
    RequiredField,
    RequiredFieldExplanation,
    ValidationObserver,
    ValidationProvider
  },
  props: {
    mentor: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      reasonForCoachingRequest: null,
      acceptTermsAndConditions: false
    }
  },
  created() {
    this.reasonForCoachingRequest = this.$store.getters['user/getUserData'].mentee.reason_for_coaching
  },
  methods: {
    show() {
      this.$refs["request-coaching-modal"].show();
    },
    requestCoaching() {
      this.$refs['request-coaching-modal-observer'].validate().then(async success => {
        if(success) {
          this.acceptTermsAndConditions = false
          this.$refs['request-coaching-modal'].hide()
          const payload = {
            mentor_id: this.mentor.id,
            reason_for_coaching_request: this.reasonForCoachingRequest
          }

          const coachingRequested = await this.$store.dispatch('user/requestCoaching', payload)
          if(coachingRequested) {
            this.$root.$bvToast.toast(this.$t('Mentorship request successfully sent.'), {
              title: this.$t('Confirmation'),
              variant: 'success',
              solid: true,
            })
            this.$bus.$emit('mentorship-request-made')
            this.$store.dispatch('user/me')
          }
          else{
            this.$root.$bvToast.toast(this.$t('An error occured, please try again later'), {
              title: this.$t('Caution'),
              variant: 'danger',
              solid: true,
            })
          }
        }
      })
    }
  },
};
</script>

<style lang="scss">
#request-coaching-modal {

  .modal-footer-class {
    justify-content: space-between;
  }

}
</style>
