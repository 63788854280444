import { render, staticRenderFns } from "./TestimonialsList.vue?vue&type=template&id=a59be35a&scoped=true&"
import script from "./TestimonialsList.vue?vue&type=script&lang=js&"
export * from "./TestimonialsList.vue?vue&type=script&lang=js&"
import style0 from "./TestimonialsList.vue?vue&type=style&index=0&id=a59be35a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a59be35a",
  null
  
)

export default component.exports