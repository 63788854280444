<template>
  <div class="testimonial-items">
    <testimonials-list-item
      v-for="testimonialItem in testimonials"
      :key="testimonialItem.id"
      :testimonial="testimonialItem"
    />
  </div>
</template>

<script>
import {

} from "bootstrap-vue";
import TestimonialsListItem from "@mentoring-platform/views/components/blocks/TestimonialsListItem.vue"

export default {
  components: {
    TestimonialsListItem
  },
  props: {
    testimonials: {
      type: Array,
      required: true
    }
  },
  data() {
    return {

    }
  }
};
</script>

<style lang="scss" scoped>
.testimonial-items {

  .testimonial-item:not(:last-child) {
    margin-bottom: 1.5rem;
  }

}
</style>
