<template>
  <div>
    <b-row class="content-header">
      <!-- Content Left -->
      <b-col
        class="content-header-left mb-1"
        cols="12"
        md="9"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <div class="breadcrumb-wrapper">
              <b-breadcrumb>
                <b-breadcrumb-item @click="$router.back()">
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="20"
                    class="align-text-top"
                  />
                  {{ $t('Back') }}
                </b-breadcrumb-item>
              </b-breadcrumb>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-card class="p-2">
      <b-row v-if="mentor">
        <b-col
          sm="12"
          md="3"
          class="text-center"
        >
          <b-img
            class="mb-1 grayscale-img"
            :src="mentor.user.photo_full_path"
            fluid
          />
          <div class="mentor-badges">
            <b-badge
              v-if="mentor.is_new"
              class="mentor-badge mr-1"
              variant="warning"
              pill
            >
              {{ $t('New') | upper }}
            </b-badge>
            <b-badge
              v-if="mentor.is_gww"
              class="mentor-badge mr-1"
              variant="info"
              pill
            >
              GWW
            </b-badge>
            <b-badge
              v-if="mentor.is_coach"
              class="mentor-badge mr-1"
              variant="primary"
              pill
            >
              {{ $t('Coach') | upper }}
            </b-badge>
          </div>
        </b-col>
        <b-col
          sm="12"
          md="8"
        >
          <b-row class="mb-2">
            <b-col>
              <mentor-availability
                :mentor="mentor"
                class="mb-1"
              />
              <h2>
                {{ mentorFullName }}
              </h2>
              <b-card-text class="mb-2">
                {{ getMentorVisibleProfessionalTitle(mentor) }}
              </b-card-text>
              <mentorship-call-to-action
                v-if="$can('read', 'mentee') || !isUserLoggedIn"
                :mentor="mentor"
              />
              <b-card-text class="font-weight-bolder">
                {{ $t('About') }}
              </b-card-text>
              <b-card-text>
                {{ getMentorVisibleDescription(mentor) }}
              </b-card-text>
            </b-col>
          </b-row>
          <b-row
            v-if="hasLinks"
            class="mb-2"
          >
            <b-col>
              <template v-for="(mentorLink, index) in mentor.links">
                <b-link
                  :key="mentorLink.id"
                  :href="mentorLink.url"
                  target="_blank"
                >
                  {{ mentorLink.url }}
                </b-link>
                <template v-if="index < mentor.links.length - 1">
                  ,
                </template>
              </template>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div class="border p-2">
                <b-card-text class="font-weight-bolder">
                  {{ $t('Location') }}
                </b-card-text>
                <div class="mb-3 mentor-info">
                  <b-card-text v-if="mentorIsGreek">
                    {{ $t('State') }} {{ $t(mentor.user.county.name) }}
                  </b-card-text>
                  <b-card-text v-else>
                    {{ $t(mentor.user.country.name) }}
                  </b-card-text>
                </div>
                <b-card-text class="font-weight-bolder">
                  {{ $t('Industry') }}
                </b-card-text>
                <div class="mb-3 mentor-info">
                  <b-card-text
                    v-for="(industry, index) in mentor.user.industries"
                    :key="index"
                  >
                    {{ $t(industry.name) }}
                  </b-card-text>
                </div>
                <b-card-text class="font-weight-bolder">
                  {{ $t('FieldOfSupport') }}
                </b-card-text>
                <b-card-text
                  v-for="(fieldOfSupport, index) in mentor.fields_of_support"
                  :key="index"
                  class="mentor-info"
                >
                  {{ $t(fieldOfSupport.name) }}
                </b-card-text>
              </div>
            </b-col>
          </b-row>
          <mentor-stats :mentor="mentor" />
        </b-col>
      </b-row>
    </b-card>
    <b-card
      v-if="showTestimonials"
      no-body
      class="p-2"
    >
      <b-card-header>
        <b-card-title>
          {{ $t('Testimonials') }}
        </b-card-title>
      </b-card-header>
      <b-card-body>
        <testimonials-list :testimonials="testimonials" />
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BBadge,
  BCard,
  BCardBody,
  BCardHeader,
  BCardText,
  BCardTitle,
  BCol,
  BImg,
  BLink,
  BRow,
  BBreadcrumb,
  BBreadcrumbItem
} from 'bootstrap-vue'
import { upper } from '@mentoring-platform/utils/filters'
import { useMentorRepository, useCountryRepository, useTestimonial, useMentorHelpers } from '@mentoring-platform/composables'
import MentorAvailability from '@mentoring-platform/views/components/blocks/Mentor/MentorAvailability.vue'
import MentorStats from '@mentoring-platform/views/components/blocks/Mentor/MentorStats.vue'
import MentorshipCallToAction from '@mentoring-platform/views/components/blocks/Mentor/MentorshipCallToAction.vue'
import TestimonialsList from '@mentoring-platform/views/components/blocks/TestimonialsList.vue'

export default {
  components: {
    BBadge,
    BCard,
    BCardBody,
    BCardHeader,
    BCardText,
    BCardTitle,
    BCol,
    BImg,
    BLink,
    BRow,
    BBreadcrumb,
    BBreadcrumbItem,
    MentorAvailability,
    MentorStats,
    MentorshipCallToAction,
    TestimonialsList
  },
  filters: {
    upper
  },
  data() {
    return {
      mentor: null
    }
  },
  computed: {
    isUserLoggedIn() {
      return this.$store.getters['user/isUserLoggedIn']
    },
    mentorFullName() {
      return this.getMentorVisibleFullName(this.mentor)
    },
    mentorIsGreek() {
      return this.mentor.user.country_id === this.countryIdOfGreece
    },
    showTestimonials() {
      return this.mentor?.testimonials_are_visible && this.testimonials.length > 0
    },
    hasLinks() {
      return Array.isArray(this.mentor.links) && this.mentor.links.length > 0
    }
  },
  async created() {
    this.mentor = await this.getSpecificMentor(this.$route.params.id)
    this.getTestimonials(this.$route.params.id)
  },
  setup() {
    const { getMentorVisibleFullName, getMentorVisibleProfessionalTitle, getMentorVisibleDescription } = useMentorHelpers()
    const { getSpecificMentor } = useMentorRepository()
    const { countryIdOfGreece } = useCountryRepository()
    const { testimonials, getTestimonials } = useTestimonial()

    return {
      getMentorVisibleDescription,
      getMentorVisibleFullName,
      getMentorVisibleProfessionalTitle,
      getSpecificMentor,
      countryIdOfGreece,
      testimonials,
      getTestimonials
    }
  }
}
</script>

<style lang="scss" scoped>
h2 {
  font-weight: bold;
}

.mentor-badges {
  display: flex;
  flex-wrap: wrap;

  .mentor-badge {
    margin-bottom: 5px;
    margin-top: 5px;
    padding: 5px 20px;
  }
}
</style>
