<template>
  <div class="testimonial-item pl-2">
    <b-card-text>
      {{ testimonial.statement }}
    </b-card-text>
    <b-card-text class="footnote">
      -- {{ testimonial.mentee_fullname }} <span class="font-italic">{{ testimonial.date }}</span>
    </b-card-text>
  </div>
</template>

<script>
import {
  BCardText
} from "bootstrap-vue";

export default {
  components: {
    BCardText
  },
  props: {
    testimonial: {
      type: Object,
      required: true
    }
  },
  data() {
    return {

    }
  }
};
</script>

<style lang="scss" scoped>
@import 'scss/variables/variables';

.testimonial-item {
  border-left: 2px solid $primary;
}
</style>
